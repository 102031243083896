<!-- eslint-disable no-unused-vars -->
<template>
  <h1>Strip Payment</h1>
</template>

<script>
import { xhr } from '@/utils/axios'

export default {
  name: 'strip-payment',
  data() {
    return {}
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      xhr.post(`/client/stripe/customer_portal/`).then(() => {
        // console.log('Response ::: ', response)
      })
    },
  },
}
</script>

<style></style>
